const shifts = [
  {
    id: 0,
    name: "Day Shift",
  },
  {
    id: 1,
    name: "Evening Shift",
  },
  {
    id: 2,
    name: "Night Shift",
  },
  {
    id: 3,
    name: "PRN shift",
  },
];
export default shifts;
