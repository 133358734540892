/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FacilityStatistics = {
    properties: {
        company: {
    type: 'string',
    isRequired: true,
},
        companyTotal: {
    type: 'number',
    isRequired: true,
},
        departments: {
    type: 'array',
    contains: {
        type: 'SubStatistics',
    },
    isRequired: true,
},
        shifts: {
    type: 'array',
    contains: {
        type: 'Statistics',
    },
    isRequired: true,
},
    },
};