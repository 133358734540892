import { render, staticRenderFns } from "./Statistics.vue?vue&type=template&id=0daa7f4e&scoped=true"
import script from "./Statistics.vue?vue&type=script&lang=ts"
export * from "./Statistics.vue?vue&type=script&lang=ts"
import style0 from "./Statistics.vue?vue&type=style&index=0&id=0daa7f4e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0daa7f4e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VChip,VDivider,VListItem,VSpacer})
