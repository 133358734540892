


































































import { Announcement } from "@/api";
import { selfData } from "@/common/role_utils";
import { VForm } from "@/common/vuetify_types";
import { maxFileSize, noSpace, requiredString } from "@/tool/validation";
import { Component, Vue, Prop, Ref } from "vue-property-decorator";

@Component
export default class AnnouncementForm extends Vue {
  @Ref("form") form!: VForm;
  @Prop() readonly isupdate!: boolean;
  @Prop() readonly announcement!: Announcement;

  title = this.announcement?.title || "";
  description = this.announcement?.description || "";
  saving = false;
  textRules = [requiredString, noSpace];
  fileRules = [(v: File) => maxFileSize(v, 5)];
  fileErr = "";
  counter = 0;
  documentName = "";
  url = "";
  document: File | null = null;

  async submit() {
    this.saving = true;
    if (this.form.validate()) {
      if (this.isupdate) {
        const payload = {
          id: this.announcement.id,
          companyId: this.announcement.companyId,
          employeeId: this.announcement.employeeId,
          title: this.title,
          description: this.description,
          createdAt: this.announcement.createdAt,
        } as Announcement;
        await this.$store.dispatch("announcement/updateAnnouncement", payload);
      } else {
        const payload = {
          companyId: selfData().companyId,
          employeeId: selfData().id,
          title: this.title,
          description: this.description,
          media: this.document,
        };
        await this.$store.dispatch("announcement/addAnnouncement", payload);
      }
      this.clearPayload();
      this.close();
    }

    this.saving = false;
  }

  onChangeFile(file: File) {
    this.fileErr = "";
    this.counter++;
    if (file) {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event) {
          this.documentName = file.name;
          this.url = URL.createObjectURL(file);
        }
      };
      reader.readAsArrayBuffer(file);
      this.document = file;
    }
  }

  clearPayload() {
    this.document = null;
    this.form.reset();
    this.form.resetValidation();
  }

  close() {
    this.clearPayload();
    this.$emit("close");
  }
}
