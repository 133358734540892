import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  DepartmentsService,
  Employee,
  EmployeesService,
  FacilityStatistics,
  FacilityStatisticsService,
} from "@/api";

export type FacilityState = {
  statistics: FacilityStatistics | null;
  employees: Employee[] | null;
};

const state: FacilityState = {
  statistics: null,
  employees: null,
};

const getters: GetterTree<FacilityState, RootState> = {
  statistics: state => state.statistics,
  employees: state => state.employees,
};

const mutations: MutationTree<FacilityState> = {
  SET_STATISTICS: (state, floors) => {
    state.statistics = floors;
  },
  SET_EMPLOYEES: (state, employees: Employee[]) => {
    state.employees = employees;
  },
};

const actions: ActionTree<FacilityState, RootState> = {
  fetchCompanyStatistics: async (
    { commit, rootState, getters },
    force = false,
  ) => {
    let hasBenefits = getters["statistics"] ? true : false;
    if (force) hasBenefits = false;
    const companyId = rootState.appContext.company?.id;
    if (hasBenefits) return;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    try {
      const statistics = await FacilityStatisticsService.getFacilityStatistics({
        companyId,
      });
      commit("SET_STATISTICS", statistics);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchDepartmentEmployees: async (
    { commit, rootState },
    departmentId: number,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      return;
    }
    try {
      const departmentEmployees = await DepartmentsService.listDepartmentEmployees(
        {
          companyId,
          departmentId,
        },
      );
      commit("SET_EMPLOYEES", departmentEmployees);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  fetchTitledEmployee: async (
    { commit, rootState },
    departmentTitleId: number,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const employeesList = await EmployeesService.listEmployees({
          companyId,
          departmentTitleId,
        });
        commit("SET_EMPLOYEES", employeesList);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  fetchEmployees: async (
    { commit, rootState },
    { shift, departmentId }: { shift: number; departmentId: number },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (companyId) {
      try {
        const employeesList = await FacilityStatisticsService.getEmployees({
          companyId,
          shift,
          departmentId,
        });
        commit("SET_EMPLOYEES", employeesList);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
};

export const facility: Module<FacilityState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
