var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-fill fill-height flex-column ma-2 pa-2"},[(_vm.loading)?_c('loading',{attrs:{"loading":_vm.loading,"size":70,"width":7}}):[_c('v-btn',{staticClass:"ma-4",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('h1',{staticClass:" ma-4"},[_vm._v(_vm._s(_vm.title))]),_c('v-data-table',{staticClass:"elevation-1 ma-4",attrs:{"headers":_vm.headers,"items":_vm.employees,"items-per-page":-1,"hide-default-footer":"","no-data-text":"No employees found"},scopedSlots:_vm._u([{key:"item.profile",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"grey","size":"30px"}},[(item.profilePicture)?_c('img',{attrs:{"src":item.profilePicture}}):_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.firstName[0] + item.lastName[0]))])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.firstName) + " " + (item.lastName))))])]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDepartmentName(item)))])]}},{key:"item.shift",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getShift(item)))])]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }