






































































import { Component, Vue } from "vue-property-decorator";
import AnnouncementCard from "@/components/announcement/AnnouncementCard.vue";
import AnnouncementForm from "@/components/announcement/AnnouncementForm.vue";
import * as roleUtils from "@/common/role_utils";

@Component({
  components: {
    AnnouncementCard,
    AnnouncementForm,
  },
})
export default class AnnouncementLayout extends Vue {
  announcementDialog = false;
  fetchingMore = false;
  counter = 0;
  offset = 0;

  created() {
    this.offset = 0;
    this.$store.dispatch("announcement/fetchAnnouncements", this.offset);
  }

  get isOpen() {
    return this.$store.getters["announcement/announcementTab"];
  }

  set isOpen(value: boolean) {
    if (!value) this.$store.dispatch("announcement/toggleAnnouncementTab");
  }

  get announcements() {
    return this.$store.getters["announcement/announcements"];
  }

  get isAdmin() {
    return roleUtils.isAdmin();
  }

  get fetchMore() {
    return this.$store.getters["announcement/fetchMore"];
  }

  async onIntersect() {
    if (!this.fetchMore) return;
    this.offset++;
    this.fetchingMore = true;
    await this.$store.dispatch("announcement/fetchAnnouncements", this.offset);
    this.fetchingMore = false;
  }
}
