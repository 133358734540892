










































































import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/loader/loading.vue";

@Component({
  components: {
    Loading,
  },
})
export default class CompanyStatistics extends Vue {
  loading = false;

  async created() {
    this.loading = true;
    await this.$store.dispatch("facility/fetchCompanyStatistics");
    this.loading = false;
  }

  get statistics() {
    return this.$store.getters["facility/statistics"];
  }

  companyBreakdown() {
    this.$router.push({
      path: "/statistics/breakdown",
      query: {
        title: "Company Members",
      },
    });
  }

  departmentBreakdown(title: string, departmentId: number) {
    this.$router.push({
      path: "/statistics/breakdown",
      query: {
        departmentId: `${departmentId}`,
        title: `${title} Members`,
      },
    });
  }

  titleBreakdown(title: string, titleId: number) {
    this.$router.push({
      path: "/statistics/breakdown",
      query: {
        titleId: `${titleId}`,
        title: `${title} Members`,
      },
    });
  }

  departmentShiftBreakdown(title: string, shift: number, departmentId: number) {
    this.$router.push({
      path: "/statistics/breakdown",
      query: {
        shift: `${shift}`,
        departmentId: `${departmentId}`,
        title: `${title} Members`,
      },
    });
  }

  shiftBreakdown(title: string, shift: number) {
    this.$router.push({
      path: "/statistics/breakdown",
      query: {
        shift: `${shift}`,
        title: `${title} Members`,
      },
    });
  }
}
