







































































































































































































import { Census, CompanyExpense, Department } from "@/api";
import { addDayOnDate, dateCheckifExist } from "@/common/date_format";
import _ from "lodash";
import moment from "moment";
import { Vue, Component } from "vue-property-decorator";
import ExpenseForm from "@/views/expense_report/ExpenseForm.vue";
import ReportMobile from "./ReportMobile.vue";
import AddBudgetConfiramtion from "../AddBudgetConfirmation.vue";
import { formatCurrency } from "@/tool/formatCurrency";

type TableRow = {
  departmentId: number;
  categoryId: number;
  department: string;
  spend: string;
  balance: number;
  percent: string;
  actual: string;
  ppd: string;
  variance: string;
};

@Component({
  components: {
    ExpenseForm,
    ReportMobile,
    AddBudgetConfiramtion,
  },
})
export default class OwnerReport extends Vue {
  date = new Date().toISOString().substring(0, 7);
  days = moment()
    .endOf("month")
    .format("DD");
  formattedDate = moment(this.date).format("MMMM");
  menu = false;
  departmentId = 0;
  budget = 0;
  remaining = 0;
  categoryId = 0;
  popDialog = false;
  popBudgetDialog = false;
  loadingData = false;
  key = 0;
  maxDate = new Date().toISOString().substring(0, 7);
  selected = [];
  formatCurrency = formatCurrency;
  headers = [
    {
      text: "Department",
      value: "department",
      sortable: false,
    },
    { text: "MTD Spend", value: "spend", sortable: false, align: "center" },
    { text: "Remaining", value: "balance", sortable: false, align: "center" },
    { text: "%", value: "percent", sortable: false, align: "center" },
    { text: "Actual PPD", value: "actual", sortable: false, align: "center" },
    { text: "Target PPD", value: "ppd", sortable: false, align: "center" },
    { text: "Variance", value: "variance", sortable: false, align: "center" },
  ];

  async created() {
    await this.fetchCompanyExpenses();
  }

  get census() {
    const companyCensus = this.$store.getters[
      "categoryBudget/census"
    ] as Census;
    const census = companyCensus?.census || 0;
    return census;
  }

  get departments() {
    return this.$store.getters["categoryBudget/departments"]?.map(
      (department: Department) => {
        return {
          ...department,
          header: undefined,
        };
      },
    );
  }

  async getDepartmentCategories() {
    this.categoryId = 0;
    await this.$store.dispatch("departmentAdmin/getCategories", {
      departmentId: this.departmentId,
    });
  }

  get categories() {
    const categoryList =
      this.$store.getters["departmentAdmin/categories"] || [];
    return _.orderBy(categoryList, ["category"], ["asc"]);
  }

  get companyExpense() {
    this.budget = 0;
    this.remaining = 0;
    const expenses = this.$store.getters["categoryBudget/companyExpenses"];

    const departmentId = this.departmentId !== 0 ? this.departmentId : 0;
    const categoryId = this.categoryId !== 0 ? this.categoryId : 0;
    const cpexpenses = JSON.parse(JSON.stringify(expenses || []));
    const tablerows: TableRow[] = [];
    cpexpenses.map((row: CompanyExpense) => {
      const budget = row.budget || 0;
      this.budget += budget;
      const balance = row.balance || 0;
      this.remaining += balance;
      const spend = balance < 0 ? budget + Math.abs(balance) : budget - balance;
      const percent = ((spend / budget) * 100).toFixed(2) + "%";
      const actual = (spend / (parseInt(this.days) * this.census)).toFixed(2);
      const variance = balance < 0 ? `Over Budget` : `OK`;
      tablerows.push({
        departmentId: row.departmentId || 0,
        categoryId: row.categoryId || 0,
        department: `${row.department} - ${row.category}`,
        spend: spend.toFixed(2),
        balance: parseFloat(balance.toFixed(2)),
        percent,
        actual,
        ppd: (row.ppd || 0).toFixed(2),
        variance,
      });
    });

    return tablerows.filter(row => {
      if (departmentId && categoryId) return categoryId === row.categoryId;
      if (departmentId) return departmentId === row.departmentId;
      else return true;
    });
  }

  get fetchUpdate() {
    const res = this.$store.getters["categoryBudget/fetchExpenses"];

    const isCurrentMonth = moment(this.date, "YYYY-MM").isSame(
      moment(),
      "month",
    );

    if (isCurrentMonth) this.fetchCompanyExpenses();
    return res;
  }

  formatDate() {
    this.formattedDate = moment(this.date).format("MMMM");
  }

  updateDate() {
    const [year, month] = this.date.split("-");
    const date = moment({
      year: parseInt(year),
      month: parseInt(month) - 1,
      day: 1,
    });
    const beginAt = date.format("YYYY-MM-DD");
    const endAt = date.endOf("month").format("YYYY-MM-DD");
    [, , this.days] = endAt.split("-");
    this.fetchCompanyExpenses(beginAt, endAt);
  }

  clearFilter() {
    this.departmentId = 0;
    this.categoryId = 0;
    this.date = new Date().toISOString().substring(0, 7);
    this.days = moment()
      .endOf("month")
      .format("DD");
    this.formattedDate = moment(this.date).format("MMMM");
    this.fetchCompanyExpenses();
  }

  async fetchCompanyExpenses(beginDate?: string, endDate?: string) {
    this.loadingData = true;
    const { beginAt, endAt } = dateCheckifExist(beginDate, endDate);
    // eslint-disable-next-line
    const nextMonth = addDayOnDate(endAt!);

    const census = this.$store.dispatch("categoryBudget/getCensus", {
      beginAt,
      endAt: nextMonth,
    });
    const departments = this.$store.dispatch("categoryBudget/getDepartments", {
      beginAt,
      endAt: nextMonth,
    });
    const expenses = this.$store.dispatch("categoryBudget/getCompanyExpenses", {
      beginAt,
      endAt: nextMonth,
    });
    await Promise.all([census, departments, expenses]);
    this.loadingData = false;

    const day = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    if (day === beginAt && !this.companyExpense?.length) {
      this.$store.dispatch("categoryBudget/setAskBudget", true);
    }
  }

  addExpense() {
    this.key++;
    this.popDialog = true;
  }

  selectCategory(item: TableRow) {
    this.$router.push({
      path: `/expense-report/owner/department/${item.departmentId}/category/${item.categoryId}`,
      query: {
        date: this.date,
      },
    });
  }

  filterExpense({
    departmentId,
    categoryId,
    date,
  }: {
    departmentId: number;
    categoryId: number;
    date: string;
  }) {
    if (this.date !== date) {
      this.date = date;
      this.updateDate();
    }
    this.departmentId = departmentId;
    this.categoryId = categoryId;
  }

  get askBudget() {
    const ask = this.$store.getters["categoryBudget/askBudget"];
    this.popBudgetDialog = ask;
    return this.popBudgetDialog;
  }

  closeDialog() {
    this.$store.dispatch("categoryBudget/setAskBudget", false);
  }
}
