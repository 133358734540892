import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  Announcement,
  AnnouncementList,
  AnnouncementService,
  OpenAPI,
} from "@/api";
import _ from "lodash";
import { count } from "@/common/announcement";
import resolve from "@/common/resolver";

type AnnouncementState = {
  announcements: AnnouncementList;
  announcementTab: boolean;
  totalAnnouncement: number;
  fetchMore: boolean;
};

const state: AnnouncementState = {
  announcements: [],
  announcementTab: false,
  totalAnnouncement: 0,
  fetchMore: false,
};

const getters: GetterTree<AnnouncementState, RootState> = {
  announcements: state => state.announcements,
  announcementTab: state => state.announcementTab,
  totalAnnouncement: state => state.totalAnnouncement,
  fetchMore: state => state.fetchMore,
};

const mutations: MutationTree<AnnouncementState> = {
  SET_FETCHMORE: (state, fetchMore) => {
    state.fetchMore = fetchMore;
  },
  SET_TOTAL_ANNOUNCEMENT: (state, totalAnnouncement) => {
    state.totalAnnouncement = totalAnnouncement;
  },
  SET_ANNOUNCE_TAB: (state, announcementTab) => {
    state.announcementTab = announcementTab;
  },
  SET_ANNOUNCEMENTS: (state, moreAnnouncements) => {
    let announcements = [];
    if (Array.isArray(moreAnnouncements)) {
      announcements = _.concat(state.announcements, moreAnnouncements);
    } else {
      announcements = state.announcements;
      announcements.push(moreAnnouncements);
    }
    state.announcements = _.uniqBy(
      _.orderBy(announcements, ["id"], ["desc"]),
      "id",
    );
  },
  ADD_ANNOUNCEMENTS: (state, announcement) => {
    if (!state.announcements) {
      state.announcements = [];
    }
    state.announcements.push(announcement);
    state.announcements = _.uniqBy(
      _.orderBy(state.announcements, ["id"], ["desc"]),
      "id",
    );
    const total = count(state.announcements);
    state.totalAnnouncement = total;
  },
  UPDATE_ANNOUNCEMENTS: (state, announcment) => {
    let index = state.announcements?.findIndex(
      item => item.id == announcment.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.announcements?.splice(index, 1);
      state.announcements?.push(announcment);
      state.announcements = _.uniqBy(
        _.orderBy(state.announcements, ["id"], ["desc"]),
        "id",
      );
    }
    const total = count(state.announcements);
    state.totalAnnouncement = total;
  },
  DELETE_ANNOUNCEMENTS: (state, announcement) => {
    let index = state.announcements?.findIndex(
      item => item.id == announcement.id,
    );
    index = index === undefined ? -1 : index;
    if (index > -1) {
      state.announcements?.splice(index, 1);
    }
    const total = count(state.announcements);
    state.totalAnnouncement = total;
  },
};

const actions: ActionTree<AnnouncementState, RootState> = {
  fetchAnnouncements: async ({ commit, rootState }, offset: number) => {
    commit("SET_FETCHMORE", false);
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const announcements = await AnnouncementService.getAnnouncementList({
        companyId,
        offset,
      });

      if (announcements) {
        const total = count(announcements);
        commit("SET_TOTAL_ANNOUNCEMENT", total);

        if (announcements.length < 4) {
          commit("SET_FETCHMORE", false);
        } else {
          commit("SET_FETCHMORE", true);
        }

        commit("SET_ANNOUNCEMENTS", announcements);
      } else {
        commit("SET_FETCHMORE", false);
      }
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  addAnnouncement: async (
    { commit, rootState },
    requestBody: {
      id?: number;
      companyId: number;
      employeeId: number;
      title: string;
      description: string;
      media?: File;
      createdAt?: string;
    },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const formData = new FormData();
      if (requestBody.media)
        formData.append("file", requestBody.media, requestBody.media?.name);
      formData.append("companyId", JSON.stringify(companyId));
      formData.append("employeeId", JSON.stringify(requestBody.employeeId));
      formData.append("title", requestBody.title);
      formData.append("description", requestBody.description);

      const res = await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/companies/${companyId}/announcement`,
        {
          method: "POST",
          body: formData,
          headers: {
            authorization: `Bearer ${await resolve(OpenAPI.TOKEN)}`,
          },
        },
      );

      if (!res.ok) {
        throw new Error(`Server Error: ${res.status} ${res.statusText}`);
      }

      const data = await res.json();
      commit("ADD_ANNOUNCEMENTS", data);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  updateAnnouncement: async (
    { commit, rootState },
    requestBody: Announcement,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const announcement = await AnnouncementService.updateAnnouncement({
        companyId,
        requestBody,
      });
      commit("UPDATE_ANNOUNCEMENTS", announcement);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  deleteAnnouncement: async (
    { commit, rootState },
    requestBody: Announcement,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const announcement = await AnnouncementService.deleteAnnouncement({
        companyId,
        requestBody,
      });
      commit("DELETE_ANNOUNCEMENTS", announcement);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  toggleAnnouncementTab: ({ commit, state }) => {
    commit("SET_ANNOUNCE_TAB", !state.announcementTab);
  },
};

export const announcement: Module<AnnouncementState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
