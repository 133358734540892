











































import { Suggestion, Suggestions } from "@/api";
import { dateFormat } from "@/common/date_format";
import {
  getSuggestionStatus,
  getSuggestionStatusColor,
} from "@/common/suggestion_status";
import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/loader/loading.vue";

@Component({
  components: {
    Loading,
  },
})
export default class SuggestionLists extends Vue {
  title = this.$route.query.title;
  benefit = this.$route.params.benefit;
  loading = false;
  formatDate = dateFormat;
  statusColor = getSuggestionStatusColor;
  status = getSuggestionStatus;
  headers = [
    { text: "Submitted By", value: "suggestionBy" },
    { text: "Title", value: "title" },
    { text: "Date Submitted", value: "createdAt" },
    { text: "Status", value: "status" },
    { text: "Likes", value: "likes", sortable: false },
  ];

  async created() {
    if (this.benefit) {
      this.loading = true;
      const beginAt = this.$route.query.startDate;
      const endAt = this.$route.query.endDate;
      await this.$store.dispatch("suggestion/fetchSuggestions", {
        benefit: this.benefit,
        beginAt: beginAt,
        endAt: endAt,
      });
      this.loading = false;
    }
  }

  get suggestions(): Suggestions {
    return this.$store.getters["suggestion/suggestions"];
  }

  viewSuggestionDetails(suggestion: Suggestion) {
    this.$store.dispatch("suggestion/setSelectedSuggestion", suggestion);
    this.$router.push(`/suggestion/details/${suggestion.id}`);
  }

  toggleLike(suggestion: Suggestion) {
    this.$store.dispatch("suggestion/likeSuggestion", suggestion);
  }
}
