/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LikelyBenefit } from '../models/LikelyBenefit';
import type { LikelyBenefits } from '../models/LikelyBenefits';
import type { Suggestion } from '../models/Suggestion';
import type { SuggestionQuestions } from '../models/SuggestionQuestions';
import type { Suggestions } from '../models/Suggestions';
import { request as __request } from '../core/request';

export class SuggestionService {

    /**
     * Get Suggestion based on likely benefits
     * @returns Suggestions Suggestions list
     * @throws ApiError
     */
    public static async getSuggestions({
companyId,
benefit,
beginAt,
endAt,
}: {
/** The companyId **/
companyId: number,
benefit?: number,
beginAt?: string,
endAt?: string,
}): Promise<Suggestions> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/suggestion`,
            query: {
                'benefit': benefit,
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * Add suggestion
     * @returns Suggestion suggestion added
     * @throws ApiError
     */
    public static async addSuggestion({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Suggestion,
}): Promise<Suggestion> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/suggestion`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update suggestion
     * @returns Suggestion Suggestion updated
     * @throws ApiError
     */
    public static async updateSuggestion({
companyId,
employeeId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
requestBody: Suggestion,
}): Promise<Suggestion> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/suggestion/${employeeId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete suggestion
     * @returns Suggestion Suggestion deleted
     * @throws ApiError
     */
    public static async deleteSuggestion({
companyId,
employeeId,
requestBody,
}: {
/** The companyId **/
companyId: number,
/** The employeeId **/
employeeId: number,
requestBody: Suggestion,
}): Promise<Suggestion> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/suggestion/${employeeId}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Like a suggestion
     * @returns any to like a suggestion
     * @throws ApiError
     */
    public static async likeSuggestion({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Suggestion,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/suggestion/like`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update suggestion status
     * @returns Suggestion to like a suggestion
     * @throws ApiError
     */
    public static async updateSuggestionStatus({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Suggestion,
}): Promise<Suggestion> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/suggestion/status`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get likely benefits
     * @returns LikelyBenefits LikelyBenefit list
     * @throws ApiError
     */
    public static async getLikelyBenefits({
companyId,
beginAt,
endAt,
}: {
/** The companyId **/
companyId: number,
beginAt?: string,
endAt?: string,
}): Promise<LikelyBenefits> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/likely_benefit`,
            query: {
                'beginAt': beginAt,
                'endAt': endAt,
            },
        });
        return result.body;
    }

    /**
     * Add Likely Benefit
     * @returns LikelyBenefit Likely benefit added
     * @throws ApiError
     */
    public static async addLikelyBenefit({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: LikelyBenefit,
}): Promise<LikelyBenefit> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/likely_benefit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update likely benefit
     * @returns LikelyBenefit Suggestion updated
     * @throws ApiError
     */
    public static async updateLikelyBenefit({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: LikelyBenefit,
}): Promise<LikelyBenefit> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/likely_benefit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete likely benefit
     * @returns LikelyBenefit Likely benefit deleted
     * @throws ApiError
     */
    public static async deleteLikelyBenefit({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: LikelyBenefit,
}): Promise<LikelyBenefit> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/likely_benefit`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Get question of the suggestion form
     * @returns SuggestionQuestions Suggestions Question list
     * @throws ApiError
     */
    public static async getSuggestionFormQuestions({
companyId,
}: {
/** The companyId **/
companyId: number,
}): Promise<SuggestionQuestions> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/suggestion/form`,
        });
        return result.body;
    }

}