













































































































































import { Suggestion } from "@/api";
import { dateFormat } from "@/common/date_format";
import { downloadFromUrl } from "@/common/document_url";
import { checkSelf, isAdmin } from "@/common/role_utils";
import {
  getSuggestionStatus,
  getSuggestionStatusColor,
} from "@/common/suggestion_status";
import { Component, Vue } from "vue-property-decorator";
import SuggestionForm from "./SuggestionForm.vue";

@Component({
  components: {
    SuggestionForm,
  },
})
export default class SuggestionDetails extends Vue {
  formatDate = dateFormat;
  status = getSuggestionStatus;
  statusColor = getSuggestionStatusColor;
  isAdmin = isAdmin();
  approving = false;
  denying = false;
  archiving = false;
  suggestionFormDialog = false;
  counter = 0;

  get suggestion(): Suggestion {
    const suggest = this.$store.getters["suggestion/selectedSuggestion"];
    return JSON.parse(JSON.stringify(suggest));
  }

  isSelf(): boolean {
    return checkSelf(this.suggestion.suggestionBy.id);
  }

  async approveSuggestion() {
    this.approving = true;
    const requestBody = this.suggestion;
    requestBody.status = 1;
    await this.$store.dispatch(
      "suggestion/updateSuggestionStatus",
      requestBody,
    );
    this.approving = false;
  }

  async rejectSuggestion() {
    this.denying = true;
    const requestBody = this.suggestion;
    requestBody.status = 2;
    await this.$store.dispatch(
      "suggestion/updateSuggestionStatus",
      requestBody,
    );
    this.denying = false;
  }

  async toggleLike(suggestion: Suggestion) {
    await this.$store.dispatch("suggestion/likeSuggestion", suggestion);
    this.$store.dispatch("suggestion/likeSelectedSuggestion", suggestion);
  }

  destroyed() {
    this.$store.dispatch("suggestion/setSelectedSuggestion", null);
  }

  async deleteSuggestion() {
    this.archiving = true;
    await this.$store.dispatch("suggestion/archiveSuggestion", this.suggestion);
    this.archiving = false;
    this.$router.push("/suggestion");
  }

  isImage(media: string) {
    const res = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(media);
    return res;
  }

  async downloadFile(url: string) {
    await downloadFromUrl(url);
  }
}
