













































































































































import {
  LikelyBenefits,
  Suggestion,
  SuggestionAnswer,
  SuggestionQuestions,
} from "@/api";
import { selfData } from "@/common/role_utils";
import { VForm } from "@/common/vuetify_types";
import { requiredString } from "@/tool/validation";
import _ from "lodash";
import moment from "moment";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

@Component
export default class SuggestionForm extends Vue {
  @Ref("form") form!: VForm;
  @Prop() readonly selectedSuggestion!: Suggestion;

  fileErr = "";
  likelyBenefitError = "";
  otherBenefit = "";
  counter = 0;
  document: File[] = [];
  sending = false;
  requiredString = [requiredString];
  otherBenefitCheck = false;
  suggestion: Suggestion = this.selectedSuggestion
    ? JSON.parse(JSON.stringify(this.selectedSuggestion))
    : {
        id: 0,
        companyId: 0,
        title: "",
        status: 0,
        totalLikes: 0,
        isLiked: false,
        averageRating: 0,
        likelyBenefit: {
          id: 0,
          name: "",
        },
        suggestionBy: {
          id: selfData().id,
          name: `${selfData().firstName} ${selfData().lastName}`,
        },
        statusUpdatedBy: {
          id: 0,
          name: "",
        },
        media: [],
        answer: [],
        createdAt: moment().toISOString(),
      };

  get questions(): SuggestionQuestions {
    const ques = this.$store.getters[
      "suggestion/suggestionQuestions"
    ] as SuggestionQuestions;
    const answer: Array<SuggestionAnswer> = [];
    _.forEach(ques || [], item =>
      answer.push({
        id: 0,
        questionId: item.id,
        question: item.question,
        answer: "",
      }),
    );

    if (!this.selectedSuggestion) this.suggestion.answer = answer;

    return ques;
  }

  get benefits(): LikelyBenefits {
    return this.$store.getters["suggestion/likelyBenefits"];
  }

  onChangeFile(files: File[]) {
    this.fileErr = "";
    this.counter++;

    if (!files?.length) return;

    const fileReadPromises = files.map(file => {
      return new Promise<File>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => resolve(file);
        reader.onerror = () => reject(`Error reading file: ${file.name}`);

        reader.readAsArrayBuffer(file);
      });
    });

    Promise.all(fileReadPromises)
      .then(loadedFiles => {
        this.document = [...loadedFiles]; // ✅ Force Vue to recognize the change
        console.log("Updated document list:", this.document);
      })
      .catch(error => {
        console.error(error);
        this.fileErr = "Error reading one or more files.";
      });
  }

  changeToOther() {
    this.suggestion.likelyBenefit = {
      id: 0,
      name: "",
    };
  }

  changeToLikely() {
    this.otherBenefitCheck = false;
  }

  close() {
    this.form.reset();
    this.$emit("close");
  }

  async submit() {
    this.likelyBenefitError = "";
    this.fileErr = "";
    let invalidSize = false;

    this.document.forEach(file => {
      const maxSize = 5 * 1024 * 1024;
      if (file.size >= maxSize) {
        invalidSize = true;
        this.fileErr = `${this.fileErr} ${this.fileErr ? "," : ""} ${
          file.name
        }`;
      }
    });

    if (
      !this.form.validate() ||
      this.suggestion.likelyBenefit.id === -1 ||
      invalidSize
    ) {
      this.likelyBenefitError = "Required";
      this.fileErr = this.fileErr
        ? `${this.fileErr} have size more then 5 mb`
        : "";
      return;
    }

    this.sending = true;
    await this.$nextTick();
    if (this.otherBenefitCheck) {
      this.suggestion.likelyBenefit = {
        id: 0,
        name: this.otherBenefit,
      };
    }

    if (this.selectedSuggestion) {
      await this.$store.dispatch(
        "suggestion/updateSuggestion",
        this.suggestion,
      );
    } else {
      await this.$store.dispatch("suggestion/suggestionMedia", {
        suggestion: this.suggestion,
        files: this.document,
      });
    }

    this.sending = false;
    this.close();
  }
}
