/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Statistics = {
    properties: {
        id: {
    type: 'number',
},
        category: {
    type: 'string',
    isRequired: true,
},
        total: {
    type: 'number',
    isRequired: true,
},
    },
};