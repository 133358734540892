





















































































































































































import { LikelyBenefit, LikelyBenefits, Suggestion, Suggestions } from "@/api";
import { monthStamp } from "@/tool/beginAtEndAt";
import { Component, Vue } from "vue-property-decorator";
import { dateFormat } from "@/common/date_format";
import {
  getSuggestionStatus,
  getSuggestionStatusColor,
} from "@/common/suggestion_status";
import SuggestionForm from "@/views/suggestion/SuggestionForm.vue";
import _ from "lodash";
import Loading from "@/components/loader/loading.vue";

@Component({
  components: {
    SuggestionForm,
    Loading,
  },
})
export default class SuggestionDashboard extends Vue {
  loading = false;
  showStartDate = false;
  showEndDate = false;
  startDate: string | null = null;
  endDate: string | null = null;
  formattedStartDate = "";
  formattedEndDate = "";
  formErrors = "";
  suggestionFormDialog = false;
  format = dateFormat;
  suggestionStatus = getSuggestionStatus;
  suggestionStatusColor = getSuggestionStatusColor;
  counter = 0;
  search = "";

  async created() {
    this.loading = true;
    const stamp = monthStamp();
    this.startDate = stamp.beginAt;
    this.endDate = stamp.endAt;
    const suggestions = this.$store.dispatch("suggestion/fetchSuggestions", {
      benefit: undefined,
      beginAt: stamp.beginAt,
      endAt: stamp.endAt,
    });

    const likelyBenefits = this.$store.dispatch(
      "suggestion/fetchLikelyBenefits",
      { beginAt: stamp.beginAt, endAt: stamp.endAt, force: false },
    );

    const questions = this.$store.dispatch("suggestion/fetchQuestions");
    await Promise.all([likelyBenefits, suggestions, questions]);
    this.loading = false;
  }

  get suggestions(): Suggestions {
    return this.$store.getters["suggestion/suggestions"];
  }

  get likelyBenefits(): LikelyBenefits {
    const benefits = this.$store.getters[
      "suggestion/likelyBenefits"
    ] as LikelyBenefits;
    if (this.search) {
      return _.filter(benefits || [], benefit =>
        _.includes(benefit.benefit.toLowerCase(), this.search.toLowerCase()),
      );
    }

    return benefits;
  }

  viewCategory(benefit: LikelyBenefit) {
    this.$router.push({
      path: `/suggestion/lists/${benefit.id}`,
      query: {
        title: benefit.benefit,
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
  }

  viewSuggestionDetails(suggestion: Suggestion) {
    this.$store.dispatch("suggestion/setSelectedSuggestion", suggestion);
    this.$router.push(`/suggestion/details/${suggestion.id}`);
  }

  toggleLike(suggestion: Suggestion) {
    this.$store.dispatch("suggestion/likeSuggestion", suggestion);
  }

  formatDate(date: string | null, isStartDate: boolean) {
    if (!date) return;
    if (isStartDate) this.formattedStartDate = dateFormat(date);
    else this.formattedEndDate = dateFormat(date);
  }

  async selectedDate() {
    this.formErrors = "";
    if (this.startDate && this.endDate) {
      if (this.startDate < this.endDate) {
        this.$store.dispatch("suggestion/fetchLikelyBenefits", {
          beginAt: this.startDate,
          endAt: this.endDate,
          force: true,
        });
      } else {
        this.formErrors = "start date should not be greater than end date";
      }
    } else {
      this.formErrors = "require both dates";
    }
  }

  clearSearch() {
    this.search = "";

    const stamp = monthStamp();
    this.startDate = stamp.beginAt;
    this.endDate = stamp.endAt;
    this.$store.dispatch("suggestion/fetchLikelyBenefits", {
      beginAt: this.startDate,
      endAt: this.endDate,
      force: true,
    });
  }
}
