import { RouteConfig } from "vue-router";
import SuggestionDashboard from "@/views/suggestion/SuggestionDashboard.vue";
import SuggestionLists from "@/views/suggestion/SuggestionLists.vue";
import SuggestionDetails from "@/views/suggestion/SuggestionDetails.vue";

const routes: Array<RouteConfig> = [
  {
    path: "",
    components: {
      default: SuggestionDashboard,
      dashboard: SuggestionDashboard,
      lists: SuggestionLists,
      details: SuggestionDetails,
    },
    meta: {
      title: "Suggestion Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "lists/:benefit",
    name: "lists",
    components: {
      default: SuggestionLists,
      dashboard: SuggestionDashboard,
      lists: SuggestionLists,
      details: SuggestionDetails,
    },
    meta: {
      title: "Suggestion Lists",
      requiresAuth: true,
    },
  },
  {
    path: "details/:id",
    name: "details",
    components: {
      default: SuggestionDetails,
      dashboard: SuggestionDashboard,
      lists: SuggestionLists,
      details: SuggestionDetails,
    },
    meta: {
      title: "Suggestion Details",
      requiresAuth: true,
    },
  },
];

export default routes;
