/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Suggestion = {
    properties: {
        id: {
    type: 'number',
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        title: {
    type: 'string',
},
        status: {
    type: 'number',
},
        totalLikes: {
    type: 'number',
    isRequired: true,
},
        isLiked: {
    type: 'boolean',
},
        averageRating: {
    type: 'number',
    isRequired: true,
    format: 'float',
},
        likelyBenefit: {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
    },
    isRequired: true,
},
        suggestionBy: {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
    },
    isRequired: true,
},
        statusUpdatedBy: {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
    },
},
        media: {
    type: 'array',
    contains: {
    type: 'string',
},
},
        answer: {
    type: 'array',
    contains: {
        type: 'SuggestionAnswer',
    },
    isRequired: true,
},
        createdAt: {
    type: 'string',
},
    },
};