import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  LikelyBenefits,
  OpenAPI,
  Suggestion,
  SuggestionQuestions,
  SuggestionService,
} from "@/api";
import _ from "lodash";
import resolve from "@/common/resolver";
import { selfData } from "@/common/role_utils";

type SuggestionState = {
  suggestions: Suggestion[];
  selectedSuggestion: Suggestion | null;
  likelyBenefits: LikelyBenefits | null;
  suggestionQuestions: SuggestionQuestions | null;
};

const state: SuggestionState = {
  suggestions: [],
  selectedSuggestion: null,
  likelyBenefits: null,
  suggestionQuestions: null,
};

const getters: GetterTree<SuggestionState, RootState> = {
  suggestions: state => state.suggestions,
  selectedSuggestion: state => state.selectedSuggestion,
  likelyBenefits: state => state.likelyBenefits,
  suggestionQuestions: state => state.suggestionQuestions,
};

const mutations: MutationTree<SuggestionState> = {
  SET_SUGGESTIONS: (state, suggestions) => {
    state.suggestions = suggestions;
  },
  SET_SELECTED_SUGGESTION: (state, suggestions) => {
    state.selectedSuggestion = suggestions;
  },
  SET_LIKELY_BENEFTIS: (state, likelyBenefits) => {
    state.likelyBenefits = likelyBenefits;
  },
  SET_LIKE_SUGGESTION: (state, suggestion) => {
    const sug = state.suggestions.find(item => item.id == suggestion.id);
    if (sug) {
      let index = state.suggestions?.findIndex(
        item => item.id == suggestion.id,
      );
      index = index === undefined ? -1 : index;
      if (index > -1) {
        state.suggestions?.splice(index, 1);
        state.suggestions?.push(sug);
      }

      state.suggestions = _.uniqBy(state.suggestions, "id");
      state.suggestions = _.orderBy(state.suggestions, ["createdAt"], ["desc"]);
    }
  },
  UPDATE_SUGGESTION_STATUS: (state, suggestion) => {
    const sug = state.suggestions.find(item => item.id == suggestion.id);
    if (sug) {
      sug.status = suggestion.status;
      state.selectedSuggestion = sug;
    }
  },
  SET_SUGGESTION_QUESTIONS: (state, questions) => {
    state.suggestionQuestions = questions;
  },
};

const actions: ActionTree<SuggestionState, RootState> = {
  fetchSuggestions: async (
    { commit, rootState },
    {
      benefit,
      beginAt,
      endAt,
    }: { benefit?: number; beginAt?: string; endAt?: string },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const suggestions = await SuggestionService.getSuggestions({
        companyId,
        benefit,
        beginAt,
        endAt,
      });
      commit("SET_SUGGESTIONS", suggestions);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  setSelectedSuggestion: ({ commit }, suggestion) => {
    commit("SET_SELECTED_SUGGESTION", suggestion);
  },
  fetchLikelyBenefits: async (
    { commit, rootState, getters },
    {
      beginAt,
      endAt,
      force,
    }: { beginAt?: string; endAt?: string; force: boolean },
  ) => {
    const companyId = rootState.appContext.company?.id;
    let hasBenefits = getters["likelyBenefits"] ? true : false;
    if (force) hasBenefits = false;
    if (companyId && !hasBenefits) {
      try {
        const likelyBenefits = await SuggestionService.getLikelyBenefits({
          companyId,
          beginAt,
          endAt,
        });
        commit("SET_LIKELY_BENEFTIS", likelyBenefits);
      } catch (error) {
        if (error instanceof Error) {
          commit("alert/SET_SHOW_ERROR", error.message, { root: true });
        }
      }
    }
  },
  likeSuggestion: ({ commit, rootState }, requestBody: Suggestion) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    requestBody.isLiked = !requestBody.isLiked;
    requestBody.totalLikes += requestBody.isLiked ? 1 : -1;
    try {
      SuggestionService.likeSuggestion({
        companyId,
        requestBody,
      });
      commit("SET_LIKE_SUGGESTION", requestBody);
    } catch (error) {
      requestBody.isLiked = !requestBody.isLiked;
      requestBody.totalLikes += requestBody.isLiked ? 1 : -1;
      commit("SET_LIKE_SUGGESTION", requestBody);
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  likeSelectedSuggestion: ({ commit }, requestBody: Suggestion) => {
    commit("SET_SELECTED_SUGGESTION", requestBody);
  },
  updateSuggestionStatus: async (
    { commit, rootState },
    requestBody: Suggestion,
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      await SuggestionService.updateSuggestionStatus({
        companyId,
        requestBody,
      });
      commit("UPDATE_SUGGESTION_STATUS", requestBody);
    } catch (error) {
      if (error instanceof Error) {
        commit("alert/SET_SHOW_ERROR", error.message, { root: true });
      }
    }
  },
  suggestionMedia: async (
    { commit, rootState },
    { suggestion, files }: { suggestion: Suggestion; files: File[] },
  ) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }

    const formData = new FormData();
    if (files) {
      files.forEach(file => {
        formData.append("files", file, file?.name);
      });
    }

    suggestion.companyId = companyId;
    const reqSuggestion = JSON.stringify(suggestion);
    formData.append("suggestion", reqSuggestion);

    try {
      await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/companies/${companyId}/suggestion`,
        {
          method: "POST",
          body: formData,
          headers: {
            authorization: `Bearer ${await resolve(OpenAPI.TOKEN)}`,
          },
        },
      );
    } catch (e) {
      if (e instanceof Error) {
        commit("alert/SET_SHOW_ERROR", e.message, { root: true });
      }
    }
  },
  fetchQuestions: async ({ commit, rootState, getters }, force = false) => {
    const companyId = rootState.appContext.company?.id;
    let hasQuestions = getters["suggestionQuestions"] ? true : false;
    if (force) hasQuestions = false;
    if (companyId && !hasQuestions) {
      try {
        const questions = await SuggestionService.getSuggestionFormQuestions({
          companyId,
        });
        commit("SET_SUGGESTION_QUESTIONS", questions);
      } catch (e) {
        if (e instanceof Error) {
          commit("alert/SET_SHOW_ERROR", e.message, { root: true });
        }
      }
    }
  },
  updateSuggestion: async ({ commit, rootState }, suggestion: Suggestion) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      const res = await SuggestionService.updateSuggestion({
        companyId,
        employeeId: selfData().id,
        requestBody: suggestion,
      });
      commit("SET_SELECTED_SUGGESTION", res);
    } catch (e) {
      if (e instanceof Error) {
        commit("alert/SET_SHOW_ERROR", e.message, { root: true });
      }
    }
  },
  archiveSuggestion: async ({ commit, rootState }, suggestion: Suggestion) => {
    const companyId = rootState.appContext.company?.id;
    if (!companyId) {
      commit("alert/SET_SHOW_ERROR", "Company Id is invalid", { root: true });
      return;
    }
    try {
      await SuggestionService.deleteSuggestion({
        companyId,
        employeeId: selfData().id,
        requestBody: suggestion,
      });
    } catch (e) {
      if (e instanceof Error) {
        commit("alert/SET_SHOW_ERROR", e.message, { root: true });
      }
    }
  },
};

export const suggestion: Module<SuggestionState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
