






































import { Employee } from "@/api";
import shifts from "@/common/shift_type";
import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/loader/loading.vue";

@Component({
  components: {
    Loading,
  },
})
export default class StatisticsBreakdown extends Vue {
  loading = false;
  title = this.$route.query.title;
  departmentId = this.$route.query.departmentId;
  titleId = this.$route.query.titleId;
  shift = this.$route.query.shift;
  headers = [
    {
      text: "Profile",
      align: "start",
      sortable: false,
      value: "profile",
    },
    { text: "Name", align: "start", sortable: false, value: "name" },
    {
      text: "Primary Department",
      align: "center",
      sortable: false,
      value: "department",
    },
    { text: "Email", align: "center", sortable: false, value: "email" },
    { text: "Title", align: "center", sortable: false, value: "title" },
    { text: "Shift", align: "end", sortable: false, value: "shift" },
  ];

  async created() {
    this.loading = true;
    const payload = {
      departmentId: this.departmentId,
      titleId: this.titleId,
      shift: this.shift,
    };
    await this.$store.dispatch("facility/fetchEmployees", payload);
    this.loading = false;
  }

  get employees() {
    return (this.$store.getters["facility/employees"] as Array<Employee>) || [];
  }

  getDepartmentName(employee: Employee): string {
    const department = employee.departmentAssignments.find(
      assignment => assignment.departmentId == employee.primaryDepartmentId,
    );

    return department?.departmentName ?? "No Department";
  }

  getShift(employee: Employee): string {
    const shift = shifts.find(item => item.id == employee.shift);
    return shift?.name || "Shift not confirmed";
  }
}
