import { AnnouncementList } from "@/api";
import moment from "moment";

const count = (announcements: AnnouncementList) => {
  let count = 0;
  for (let i = 0; i < announcements.length; i++) {
    if (moment(announcements[i].createdAt).isSame(moment(), "day")) {
      count++;
    }
  }

  return count;
};

export { count };
