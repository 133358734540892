const suggestionStatus = [
  {
    id: 0,
    status: "Pending",
    color: "orange",
  },
  {
    id: 1,
    status: "Approved",
    color: "green",
  },
  {
    id: 2,
    status: "Denied",
    color: "red",
  },
];

const getSuggestionStatus = (status?: number): string | undefined => {
  const res = suggestionStatus.find(item => item.id == status);
  return res?.status;
};

const getSuggestionStatusColor = (status?: number): string | undefined => {
  const res = suggestionStatus.find(item => item.id == status);
  return res?.color;
};
export { getSuggestionStatus, getSuggestionStatusColor };
