/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AnnouncementResponse = {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        companyId: {
    type: 'number',
    isRequired: true,
},
        postedBy: {
    properties: {
        id: {
    type: 'number',
    isRequired: true,
},
        name: {
    type: 'string',
    isRequired: true,
},
    },
    isRequired: true,
},
        title: {
    type: 'string',
    isRequired: true,
},
        description: {
    type: 'string',
    isRequired: true,
},
        media: {
    type: 'string',
},
        createdAt: {
    type: 'string',
    isRequired: true,
},
    },
};