




















































































































































































































import { Census, Department, Expense } from "@/api";
import { TableRow as ExpenseTableRow } from "@/common/ExpenseTableRow";
import { addDayOnDate, dateFormat } from "@/common/date_format";
import { selfData } from "@/common/role_utils";
import _ from "lodash";
import moment from "moment";
import { Vue, Component, Watch } from "vue-property-decorator";
import ExpenseForm from "@/views/expense_report/ExpenseForm.vue";
import ReportMobile from "./ReportMobile.vue";
import AddBudgetConfiramtion from "../AddBudgetConfirmation.vue";
import { formatCurrency } from "@/tool/formatCurrency";
import { calculateActualPPD } from "@/tool/budgetUtils";

@Component({
  components: {
    ExpenseForm,
    ReportMobile,
    AddBudgetConfiramtion,
  },
})
export default class AdminReport extends Vue {
  @Watch("categoryId", { immediate: true, deep: true })
  onCategoryChange(newVal: number) {
    if (newVal) {
      this.fetchExpenses();
    }
  }

  date = new Date().toISOString().substring(0, 7);
  days = moment()
    .endOf("month")
    .format("DD");
  formattedDate = moment(this.date).format("MMMM");
  menu = false;
  departmentId = selfData().primaryDepartmentId;
  categoryId = 0;
  key = 0;
  totalRemaining = 0;
  actualPPD = 0;
  popDialog = false;
  loadingData = false;
  popBudgetDialog = false;
  selected = [];
  formatCurrency = formatCurrency;
  editData: ExpenseTableRow | null = null;
  dateFormat = dateFormat;
  maxDate = new Date().toISOString().substring(0, 7);
  headers = [
    {
      text: "Number",
      value: "index",
      sortable: false,
    },
    {
      text: "Order Date",
      value: "orderDate",
      sortable: false,
      align: "center",
    },
    { text: "Vendor", value: "vendor" },
    { text: "Description", value: "description" },
    { text: "Amount", value: "amount", sortable: false, align: "center" },
    { text: "Balance", value: "balance", sortable: false, align: "center" },
    {
      text: "Date Received",
      value: "dateReceived",
      sortable: false,
      align: "center",
    },
    { text: "Invoice", value: "invoice", sortable: false, align: "center" },
    {
      text: "AP Verified",
      value: "apVerified",
      sortable: false,
      align: "center",
    },
  ];

  async created() {
    await this.getDepartmentCategories();
  }

  get census() {
    const companyCensus = this.$store.getters[
      "categoryBudget/census"
    ] as Census;
    const census = companyCensus?.census || 0;
    return census;
  }

  get departments() {
    return this.$store.getters["departmentAdmin/departments"]?.map(
      (department: Department) => {
        return {
          ...department,
          header: undefined,
        };
      },
    );
  }

  async getDepartmentCategories() {
    await this.$store.dispatch("departmentAdmin/getCategories", {
      departmentId: this.departmentId,
      beginAt: this.date,
    });
  }

  get categories() {
    const categoryList =
      this.$store.getters["departmentAdmin/categories"] || [];
    const orderCategories = _.orderBy(categoryList, ["category"], ["asc"]);
    this.categoryId = orderCategories[0]?.id || 0;
    return orderCategories;
  }

  formatDate() {
    this.formattedDate = moment(this.date).format("MMMM");
  }

  get budget() {
    const catBudget = this.$store.getters["categoryBudget/categoryBudget"];
    return catBudget;
  }

  get fetchUpdate() {
    const res = this.$store.getters["categoryBudget/fetchExpenses"];

    const isCurrentMonth = moment(this.date, "YYYY-MM").isSame(
      moment(),
      "month",
    );

    if (isCurrentMonth) this.fetchExpenses();
    return res;
  }

  get expenses() {
    const depExpenses =
      this.$store.getters["categoryBudget/departmentExpenses"] || [];

    let budget = this.budget?.budget || 0;
    const tableRows: ExpenseTableRow[] = [];
    let index = 0;
    let mtdSpend = 0;

    depExpenses.map((expense: Expense) => {
      index++;
      const balance = (budget - expense.amount).toFixed(2);
      budget = balance;
      mtdSpend += expense.amount;
      tableRows.push({
        index,
        id: expense.id,
        orderDate: expense.orderDate,
        vendor: expense.vendor,
        description: expense.description || "N/A",
        amount: expense.amount.toFixed(2),
        balance: budget,
        dateReceived: expense.receivedAt
          ? this.dateFormat(expense.receivedAt)
          : "Pending",
        invoice: expense.invoice,
        apVerified: expense.apVerified || 0,
        receipt: expense.receipt,
      });
    });
    this.actualPPD = calculateActualPPD(mtdSpend, this.census, this.date) || 0;
    return tableRows;
  }

  async fetchExpenses() {
    const [year, month] = this.date.split("-");
    const date = moment({
      year: parseInt(year),
      month: parseInt(month) - 1,
      day: 1,
    });
    const beginAt = date.format("YYYY-MM-DD");
    const endAt = addDayOnDate(date.endOf("month").format("YYYY-MM-DD"));
    [, , this.days] = endAt.split("-");
    if (this.categoryId) {
      this.loadingData = true;
      const census = this.$store.dispatch("categoryBudget/getCensus", {
        beginAt,
        endAt,
      });
      const budget = this.$store.dispatch("categoryBudget/getCategoryBudget", {
        departmentId: this.departmentId,
        categoryId: this.categoryId,
        beginAt,
        endAt,
      });
      const expenses = this.$store.dispatch(
        "categoryBudget/getDepartmentExpenses",
        {
          departmentId: this.departmentId,
          categoryId: this.categoryId,
          beginAt,
          endAt,
        },
      );
      await Promise.all([budget, expenses, census]);
      this.loadingData = false;

      const day = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      if (day === beginAt && !this.budget) {
        this.$store.dispatch("categoryBudget/setAskBudget", true);
      }

      this.totalRemaining =
        this.expenses[this.expenses.length - 1]?.balance ||
        this.budget.budget ||
        0;
    }
  }

  clearFilter() {
    this.date = new Date().toISOString().substring(0, 7);
    this.formattedDate = moment(this.date).format("MMMM");
    this.fetchExpenses();
  }

  editExpenses(item: ExpenseTableRow) {
    this.key++;
    this.editData = item;
    this.popDialog = true;
  }

  addExpense() {
    if (!this.budget?.budget) {
      this.$store.dispatch("categoryBudget/setAskBudget", true);
      return;
    }
    this.key++;
    this.editData = null;
    this.popDialog = true;
  }

  closeDialog() {
    this.$store.dispatch("categoryBudget/setAskBudget", false);
  }

  filterExpense({
    departmentId,
    categoryId,
    date,
  }: {
    departmentId: number;
    categoryId: number;
    date: string;
  }) {
    if (this.date !== date) {
      this.date = date;
      this.fetchExpenses();
    }
    this.departmentId = departmentId;
    this.categoryId = categoryId;
  }

  get askBudget() {
    const ask = this.$store.getters["categoryBudget/askBudget"];
    this.popBudgetDialog = ask;
    return this.popBudgetDialog;
  }
}
