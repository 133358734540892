/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Announcement } from '../models/Announcement';
import type { AnnouncementList } from '../models/AnnouncementList';
import type { AnnouncementResponse } from '../models/AnnouncementResponse';
import { request as __request } from '../core/request';

export class AnnouncementService {

    /**
     * Get Announcement list
     * @returns AnnouncementList announcement list of the company
     * @throws ApiError
     */
    public static async getAnnouncementList({
companyId,
offset,
}: {
/** The companyId **/
companyId: number,
offset: number,
}): Promise<AnnouncementList> {
        const result = await __request({
            method: 'GET',
            path: `/companies/${companyId}/announcement`,
            query: {
                'offset': offset,
            },
        });
        return result.body;
    }

    /**
     * Add an announcement of the company
     * @returns AnnouncementResponse added an announcement
     * @throws ApiError
     */
    public static async addAnnouncement({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Announcement,
}): Promise<AnnouncementResponse> {
        const result = await __request({
            method: 'POST',
            path: `/companies/${companyId}/announcement`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * update announcement
     * @returns AnnouncementResponse announcement updated
     * @throws ApiError
     */
    public static async updateAnnouncement({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Announcement,
}): Promise<AnnouncementResponse> {
        const result = await __request({
            method: 'PUT',
            path: `/companies/${companyId}/announcement`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * delete announcement
     * @returns AnnouncementResponse admission survey question deleted
     * @throws ApiError
     */
    public static async deleteAnnouncement({
companyId,
requestBody,
}: {
/** The companyId **/
companyId: number,
requestBody: Announcement,
}): Promise<AnnouncementResponse> {
        const result = await __request({
            method: 'DELETE',
            path: `/companies/${companyId}/announcement`,
            body: requestBody,
        });
        return result.body;
    }

}