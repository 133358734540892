/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SubStatistics = {
    properties: {
        id: {
    type: 'number',
},
        department: {
    type: 'string',
    isRequired: true,
},
        departmentTotal: {
    type: 'number',
    isRequired: true,
},
        titles: {
    type: 'array',
    contains: {
        type: 'Statistics',
    },
    isRequired: true,
},
    },
};