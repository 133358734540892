














































import { Component, Vue } from "vue-property-decorator";
import { Company, Employee } from "@/api";

@Component({
  props: {
    onMenuClicked: {
      type: Function,
    },
  },
})
export default class AppBarMain extends Vue {
  get selfInfo(): Employee {
    return this.$store.getters["getCurrentEmployeeSelf"];
  }
  get isSuperAdmin(): Employee {
    return this.$store.getters["isSuperAdmin"];
  }

  get company(): Company {
    return this.$store.getters["getSelectedCompany"];
  }

  get totalAnnouncement() {
    return this.$store.getters["announcement/totalAnnouncement"];
  }

  showSelfProfile() {
    this.$router.push({
      path: `/company-directory/profile/${this.selfInfo.id}`,
    });
  }

  toggleDrawer() {
    this.$store.dispatch("toggleNavRail");
  }

  toggleAnnouncementDrawer() {
    this.$store.dispatch("announcement/toggleAnnouncementTab");
  }
}
