var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-fill flex-column fill-height"},[(_vm.loading)?_c('loading',{attrs:{"loading":_vm.loading,"size":70,"width":7}}):_c('v-container',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('h1',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.title)+" Suggestions")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.suggestions,"items-per-page":10},on:{"click:row":_vm.viewSuggestionDetails},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.title || "No Title"))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.suggestionBy",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.suggestionBy.name))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusColor(item.status)}},[_vm._v(_vm._s(_vm.status(item.status)))])]}},{key:"item.likes",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleLike(item)}}},[_c('v-icon',{attrs:{"color":item.isLiked ? 'red' : 'grey'}},[_vm._v(" "+_vm._s(item.isLiked ? "mdi-heart" : "mdi-heart-outline")+" ")])],1),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.totalLikes))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }