import { RouteConfig } from "vue-router";
import Statistics from "@/views/facility_statistics/Statistics.vue";
import StatisticsBreakdown from "@/views/facility_statistics/StatisticsBreakdown.vue";

const routes: Array<RouteConfig> = [
  {
    path: "",
    components: {
      default: Statistics,
      statistics: Statistics,
      breakdown: StatisticsBreakdown,
    },
    meta: {
      title: "Facility Statistics",
      requiresAuth: true,
    },
  },
  {
    path: "breakdown",
    components: {
      default: StatisticsBreakdown,
      statistics: Statistics,
      breakdown: StatisticsBreakdown,
    },
    meta: {
      title: "Facility Statistics",
      requiresAuth: true,
    },
  },
];

export default routes;
